import { IterableEmbeddedMessage } from "@/features/iterable/components/IterableEmbeddedMessage";
import {
  iterable,
  iterableStore,
} from "@/features/iterable/stores/iterable.store";
import { useEffect } from "react";

export const HomepageEmbeddedMessages = () => {
  const messages = iterableStore(state => state.home);

  /** Start/stop Iterbale session. */
  useEffect(() => {
    iterable.start();

    /** Unmounted, cleanup. */
    return () => {
      iterable.end();
    };
  }, []);

  return (
    <div className="m-4 flex flex-col space-y-2 md:m-0 md:mb-4">
      {messages.map(message => (
        <IterableEmbeddedMessage
          key={message.metadata.messageId}
          message={message}
        />
      ))}
    </div>
  );
};
