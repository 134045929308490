import { ArrowRight } from "@/assets/icons/24/arrow/ArrowRight";
import {
  ITERABLE_PACKAGE_NAME,
  iterable,
} from "@/features/iterable/stores/iterable.store";
import { Heading } from "@/ui/cva/Heading";
import { Body } from "@/ui/typography/Body";
import {
  IterableEmbeddedButton,
  IterableEmbeddedMessage as Message,
  trackEmbeddedClick,
} from "@iterable/web-sdk";
import { Button } from "@/ui/cva/Button";
import { MouseEvent } from "react";

type IterableEmbeddedMessageProps = {
  message: Message;
};

export const IterableEmbeddedMessage = ({
  message,
}: IterableEmbeddedMessageProps) => {
  const title = message.elements?.title;
  const body = message.elements?.body;

  const button = message.elements?.buttons?.[0];

  const handleClick =
    (button: IterableEmbeddedButton) =>
    (evt: MouseEvent<HTMLButtonElement>) => {
      // Prevent default click behavior for the button
      evt.preventDefault();
      evt.stopPropagation();

      /**
       * For buttons where type is "openUrl", the URL is in the action.data field.
       * Otherwise, the button represents a custom action, and the URL is in the
       * action.type field.
       */
      const buttonUrl =
        button.action?.type === "openUrl"
          ? button.action.data ?? ""
          : button.action?.type ?? "";

      // Track an embeddedClick event
      trackEmbeddedClick({
        messageId: message.metadata.messageId,
        buttonIdentifier: button.id,
        targetUrl: buttonUrl,
        appPackageName: ITERABLE_PACKAGE_NAME,
      })
        .then(response => {
          if (response.status != 200) {
            console.error("trackEmbeddedClick");
          }
        })
        .catch(ex => {
          console.error("trackEmbeddedClick", ex);
        });

      // Pass the click through to the URL or custom action handlers.
      iterable.click(buttonUrl);
    };

  return (
    <div className="flex flex-col space-y-4 rounded-[0.25rem] border border-grey-300 bg-white p-6">
      {!!title && (
        <Heading as="span" size="h5">
          {title}
        </Heading>
      )}
      {!!body && <Body>{body}</Body>}
      {!!button && (
        <Button
          type="button"
          onClick={handleClick(button)}
          rightIcon={<ArrowRight />}
        >
          {button.title}
        </Button>
      )}
    </div>
  );
};
