import { HomepageAppDownloadBlock } from "@/components/Homepage/HomepageAppDownloadBlock";
import { HomepageEmbeddedMessages } from "@/components/Homepage/HomepageEmbeddedMessages";
import { useTranslation } from "next-i18next";
import { SEO as SEOComponent } from "components/common";
import useAdIsAboutToExpire from "./hooks/useAdIsAboutToExpire";
import useShowMissingPaymentInfoModal from "hooks/useShowMissingPaymentInfoModal";
import { HomepageHeroBlock } from "./HomepageHeroBlock";
import { HomepageUSPBlock } from "@/components/Homepage/HomepageUSPBlock";
import { HomepageMarketingBlock } from "@/components/Homepage/HomepageMarketingBlock";
import { HomepagePopularBrandsBlock } from "@/components/Homepage/HomepagePopularBrandsBlock";
import { HomepageCollectionsBlock } from "@/components/Homepage/HomepageCollectionsBlock";
import { HomepageFavoritesBlock } from "@/components/Homepage/HomepageFavoritesBlock";
import { HomepageLinkCloudBlock } from "@/components/Homepage/HomepageLinkCloudBlock";
import { HomepageForYouBlock } from "@/components/Homepage/HomepageForYouBlock";

export const Homepage = () => {
  const { t } = useTranslation();

  useAdIsAboutToExpire();
  useShowMissingPaymentInfoModal();

  return (
    <div className="max-w-screen-xl pt-2 xl:container md:px-4 md:pt-4 xl:mx-auto">
      <SEOComponent
        title={t("homepage:homepage-seo-title")}
        description={t("homepage:homepage-seo-description")}
      />
      <HomepageEmbeddedMessages />
      <HomepageHeroBlock />
      <HomepageUSPBlock />
      <div className="mx-4 my-10 flex flex-col space-y-10 md:mx-auto md:my-20 md:space-y-20">
        <HomepageForYouBlock />
        <HomepageMarketingBlock />
        <HomepagePopularBrandsBlock />
        <HomepageCollectionsBlock />
        <HomepageFavoritesBlock />
        <HomepageAppDownloadBlock />
        <HomepageLinkCloudBlock />
      </div>
    </div>
  );
};
